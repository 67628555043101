export default function getWorkbenchByline(xmlString) {
  const matchArray = /<Byline>.*?<FirstName>(.+?)<\/FirstName>.*?<LastName>(.+?)<\/LastName>/.exec(
    xmlString,
  );

  const firstName = (matchArray?.[1]) || '';
  const lasttName = (matchArray?.[2]) || '';

  return `${firstName} ${lasttName}`;
}
