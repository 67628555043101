import { imageAltForImage } from './imageAlt';

export const hasFeaturedAuthor = (article, type = null) => {
  if (
    (!type || type === 'article')
    && article && article.authors && article.authors[0]
    && article.authors[0].featuredAuthor.isFeaturedAuthor
  ) {
    return true;
  }

  return false;
};

export const getFeaturedAuthor = (article, type = null) => {
  let result = null;

  if (hasFeaturedAuthor(article, type)) {
    result = article.authors[0].person.name;
  }

  return result;
};

export const getArticleAuthor = (authors, contentType) => {
  if (contentType !== 'article') return null;

  if (!authors || authors.length === 0) return null;

  const [firstAuthor] = authors;
  const isFeatured = firstAuthor?.featuredAuthor?.isFeaturedAuthor;
  const name = firstAuthor?.person?.name;
  const image = {
    url: firstAuthor?.person?.primaryImage?.url?.primary,
    alt: imageAltForImage(firstAuthor?.person?.primaryImage),
  };

  return {
    isFeatured,
    name,
    image,
  };
};
