import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import {
  author as AuthorPropType,
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import { reduceCSL } from 'lib/arrayUtils';
import { generateElementKey } from 'lib/stringUtils';

import { BylineName } from './BylineName';

/**
 *
 * @param {object} props
 * @param {Author[]} props.authors
 * @param {boolean} props.showJobTitle
 * @param {string} props.additionalClasses
 */

export const BylineList = ({
  authors,
  showJobTitle,
  additionalClasses,
  isMsnbcDaily,
}) => {
  const byline = authors
    .map((author, i) => {
      const person = get(author, 'person', author);
      const name = get(person, 'name', null);
      const key = generateElementKey(name);
      return (
        <BylineName
          key={key}
          person={person}
          showJobTitle={showJobTitle}
          additionalClasses={additionalClasses}
          position={i + 1}
        />
      );
    });
  if (isMsnbcDaily) {
    return byline;
  }
  return byline.reduce(reduceCSL, []);
};

BylineList.propTypes = {
  authors: PropTypes.arrayOf(
    AuthorPropType || PersonPropType,
  ),
  showJobTitle: PropTypes.bool,
  additionalClasses: PropTypes.string,
};

BylineList.defaultProps = {
  authors: {},
  additionalClasses: '',
  showJobTitle: false,
};
