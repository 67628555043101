import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { timeFrom, getFormattedTimeString } from 'lib/DateTime';

import styles from './styles.module.scss';

const TIMESTAMP_REFRESH_INTERVAL = 60_000; // 1 minute in milliseconds

/**
  * Formats the date string and constructs the time ago string.
  *
  * @param {string} dateString - The date string to format.
  * @param {boolean} shouldDisplayTime - Flag to determine if the exact time should be displayed.
  * @param {Function} t - Translation function for localization.
  */
const formatDate = (dateString, shouldDisplayTime, t) => {
  const timeSince = timeFrom(dateString);
  const formattedTime = getFormattedTimeString(dateString);

  const timeAgo = `${t('time_ago', { time: timeSince })}`;
  const time = shouldDisplayTime ? `${timeAgo} / ${formattedTime}` : timeAgo;
  const isJustNow = timeSince === '0m';
  const formattedTimeSince = isJustNow ? `${t('Just now')}` : time;

  return { formattedDateString: formattedTimeSince, justNow: isJustNow };
};

/**
  * TimeAgo component displays a formatted time string indicating how long ago a given date was.
  *
  * @param {object} props - The properties passed to the component.
  * @param {string} props.dateString - The date string to format.
  * @param {boolean} [props.chatStyle] - Flag to apply chat style to the timestamp.
  * @param {boolean} [props.displayTime] - Flag to determine if the exact time should be displayed.
  */
function TimeAgo(props) {
  const { t } = useTranslation();
  const { dateString, chatStyle, displayTime } = props;

  const [
    { formattedDateString, justNow },
    setFormattedDateString,
  ] = useState({ formattedDaysFromDate: '', justNow: false });

  useEffect(() => {
    setFormattedDateString(formatDate(dateString, displayTime, t));

    const interval = setInterval(() => {
      setFormattedDateString(formatDate(dateString, displayTime, t));
    }, TIMESTAMP_REFRESH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [dateString, displayTime]);

  return (
    <div
      className={
        classNames(
          styles.timestamp,
          chatStyle && styles.chatStyleTimeStamp,
          justNow && styles.justNow,
        )
      }
      data-testid="timestamp"
    >
      {formattedDateString}
    </div>
  );
}

TimeAgo.propTypes = {
  dateString: PropTypes.string.isRequired,
  chatStyle: PropTypes.bool,
  displayTime: PropTypes.bool,
};

TimeAgo.defaultProps = {
  chatStyle: false,
  displayTime: true,
};

export { TimeAgo };
