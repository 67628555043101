import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Unibrow } from 'components/Unibrow';
import TypeIcon from 'components/TypeIcon';

import { unibrow as UnibrowPropType } from 'lib/CustomPropTypes';

import './styles.themed.scss';

const TeaseCategory = ({
  defaultSpacing,
  iconUrl,
  size,
  style,
  type,
  target,
  rel,
  unibrow,
  vertical,
  deepLink,
}) => {
  const isPaidHub = vertical === 'sponsoredcontent';

  return (
    <Unibrow
      className={classNames(
        'tease-category__eyebrow',
        { [`tease-category__eyebrow--${size}`]: size },
        style,
        {
          'tease-category__eyebrow--default-spacing': defaultSpacing,
          'tease-category__eyebrow--icon-spacing': ['video', 'slideshow'].includes(type),
          'bg-knockout-primary color-primary': isPaidHub,
        },
      )}
      unibrow={unibrow}
      vertical={vertical}
      target={target}
      rel={rel}
      deepLink={deepLink}
    >
      <TypeIcon
        type={type}
        url={iconUrl}
        target={target}
        className="tease-category__typeIcon"
      />
    </Unibrow>
  );
};

TeaseCategory.propTypes = {
  type: PropTypes.string.isRequired,
  unibrow: UnibrowPropType.isRequired,
  defaultSpacing: PropTypes.bool,
  iconUrl: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  vertical: PropTypes.string,
  deepLink: PropTypes.objectOf(PropTypes.any),
};

TeaseCategory.defaultProps = {
  defaultSpacing: true,
  iconUrl: '',
  size: 'small',
  style: '',
  target: null,
  rel: null,
  vertical: '',
  deepLink: null,
};

export default TeaseCategory;
