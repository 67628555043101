import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Dek({ dek, styles: propStyles }) {
  return (
    <p className={classNames(styles.dek, propStyles)}>
      {dek}
    </p>
  );
}

Dek.propTypes = {
  dek: PropTypes.string.isRequired,
  styles: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.object),
    PropTypes.string,
    PropTypes.array,
  ]),
};

Dek.defaultProps = {
  styles: '',
};

export { Dek };
