import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  package as PackagePropType,
  layoutContext as LayoutContextPropType,
} from 'lib/CustomPropTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import {
  SEE_MORE_BUTTON,
} from 'lib/brandFeatures';
import i18next from 'i18next';
import { Button } from 'components/Button';
import LeadBorderTop from 'components/LeadBorderTop';
import PackageTitle from 'components/PackageTitle';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import TitleLogo from 'components/TitleLogo';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import './styles.themed.scss';

function getPicture(item, index, packageType, isFluidWidthPage) {
  const defaultSizes = {
    s: AIMS_FLAVORS.FOCAL_440x220,
    m: AIMS_FLAVORS.FOCAL_360x360,
  };

  const alternateSizes = {
    ...defaultSizes,
    l: AIMS_FLAVORS.FOCAL_460x230,
    xl: AIMS_FLAVORS.FOCAL_580x290,
  };

  const alternateTwoUpSizes = {
    s: AIMS_FLAVORS.FOCAL_440x220,
    m: AIMS_FLAVORS.FOCAL_460x230,
    xl: AIMS_FLAVORS.FOCAL_580x290,
  };

  let sizes = alternateSizes;
  if (packageType === 'fourUp' || (packageType === 'threeUp' && index > 0)) {
    sizes = defaultSizes;
  }

  if (packageType === 'twoUp' && isFluidWidthPage) {
    sizes = alternateTwoUpSizes;
  }

  return (
    <TeasePicture
      responsiveFlavors={sizes}
      type={item.type}
      computedValues={item.computedValues}
    />
  );
}

function getTease(
  item,
  index,
  packageType,
  packageMetadata,
  vertical,
  isMobileOnlyComponent,
  isFluidWidthPage,
) {
  const { id, metadata, type } = item || {};
  let teaseStyles;
  if (type === 'liveBlog') {
    item.related = []; // eslint-disable-line
  }

  switch (packageType) {
    case 'fourUp':
      teaseStyles = 'multi-up__tease-card--four-up';
      break;

    case 'threeUp':
      if (index === 0) {
        teaseStyles = 'multi-up__tease-card--three-up-main';
        break;
      }
      teaseStyles = 'multi-up__tease-card--three-up';
      break;

    default:
      teaseStyles = 'multi-up__tease-card--two-up';
      break;
  }

  const teaseMetadata = metadata;
  const adStyles = type === 'ad' ? 'boxinlineAd' : ''; // hide ad div on Desktop

  return item && (
    <div
      key={`${id}-${index}`}
      className={classNames('multi-up__article', adStyles, teaseStyles)}
      data-test="multi-up__item"
      data-testid="multi-up__item"
    >
      <TeaseCard
        key={id}
        content={item}
        vertical={vertical}
        pkgType={packageType}
        isMobileOnlyComponent={isMobileOnlyComponent}
        showUnibrowInset={!isFluidWidthPage}
        {...packageMetadata}
        {...teaseMetadata}
      >
        {getPicture(item, index, packageType, isFluidWidthPage)}
      </TeaseCard>
    </div>
  );
}

function MultiUp(props, context = {}) {
  const {
    alternateTitleFormat,
    className,
    content,
    pkgClassName,
    vertical,
    isFirstLayout,
    isMobileOnlyComponent,
    showTitle,
    alternateAccentStripe,
  } = props;

  const { isFluidWidthPage } = context;

  if (!content || !(content.items?.[0])) return null;

  const {
    id, type, items, metadata = {}, packageIndex,
  } = content;

  const { showBottomBorder = false } = metadata;

  const classes = classNames(
    pkgClassName,
    `pkg-index-${packageIndex + 1}`,
    className,
    {
      'multi-up__has-title': metadata?.title,
      'multi-up__two-up': type === 'twoUp',
      'multi-up__three_up': type === 'threeUp',
      'multi-up__four-up': type === 'fourUp',
      alternateStripe: alternateAccentStripe,
    },
  );

  const {
    title, logoUrl, seeAllText, seeAllUrl,
  } = metadata;

  let ModuleTitle;
  if (logoUrl) {
    ModuleTitle = <TitleLogo logoUrl={logoUrl} />;
  } else if (title && showTitle) {
    ModuleTitle = (
      <PackageTitle
        alternateDisplayFormat={alternateTitleFormat}
        metadata={metadata}
      />
    );
  }

  const isFirstPackageInFirstLayout = isFirstLayout && !packageIndex;
  const articlesClasses = classNames(
    'multi-up__articles',
    {
      'multi-up__accent-line': isFirstPackageInFirstLayout,
    },
  );
  const shouldRenderButton = getFeatureConfigForBrand(
    SEE_MORE_BUTTON,
    vertical,
  );
  const showSeeAll = seeAllUrl && shouldRenderButton;

  return (
    <section
      data-packageid={id}
      className={classes}
      data-test={`${type}__section`}
      data-testid={`${type}__section`}
      data-activity-map={`multiUp-${type}-package`}
    >
      <LeadBorderTop types={['collectionLead']} />
      <div className="multi-up__content">
        {ModuleTitle}
        <div className={classNames(articlesClasses)}>
          { items.map((item, index) => getTease(item,
            index, type, metadata, vertical, isMobileOnlyComponent, isFluidWidthPage)) }
        </div>
      </div>
      {showBottomBorder && (<hr className="pkg-bottom-border" />)}
      {showSeeAll && (
        <div>
          <Button
            size="normal"
            alignment="right"
            title={seeAllText || i18next.t('See More')}
            url={seeAllUrl}
            buttonType="button-tilt"
          />
        </div>
      )}
    </section>
  );
}

MultiUp.propTypes = {
  alternateTitleFormat: PropTypes.bool,
  content: PackagePropType.isRequired,
  className: PropTypes.string,
  pkgClassName: PropTypes.string,
  vertical: PropTypes.string,
  isFirstLayout: PropTypes.bool,
  isMobileOnlyComponent: PropTypes.bool,
  showTitle: PropTypes.bool,
  alternateAccentStripe: PropTypes.bool,
};

MultiUp.defaultProps = {
  alternateTitleFormat: false,
  className: null,
  pkgClassName: 'pkg multiUp',
  vertical: '',
  isFirstLayout: false,
  isMobileOnlyComponent: false,
  showTitle: true,
  alternateAccentStripe: false,
};

MultiUp.contextTypes = {
  isFluidWidthPage: LayoutContextPropType.isFluidWidthPage,
};

export default MultiUp;
