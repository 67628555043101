// sets vertical value as react context so it can be accessed by nested components
// at any level eliminating the need for props and redux store access
// usage example: see FullWidth

import React from 'react';
import PropTypes from 'prop-types';

import { LayoutContext as LayoutContextProvider } from 'lib/ContextTypes';
import { layoutContext as layoutContextPropType } from 'lib/CustomPropTypes';

class LayoutContext extends React.Component {
  static propTypes = {
    ...layoutContextPropType,
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    ...layoutContextPropType,
  };

  /* eslint-disable react/default-props-match-prop-types */
  static defaultProps = {
    layoutIndex: 0,
    layoutType: 'fullWidth',
    vertical: null,
    pageRoute: '/',
    isFirstRail: false,
    isRailLayout: false,
    leadPackageType: null,
    curationAutofill: null,
    adsDisabled: false,
    nonPackageAdsDisabled: false,
    isFirstLayoutWithPackages: false,
    isFluidWidthPage: false,
    pageType: '',
  };
  /* eslint-disable react/default-props-match-prop-types */

  getChildContext() {
    const {
      adsDisabled,
      nonPackageAdsDisabled,
      curationAutofill,
      isFirstRail,
      isFirstLayoutWithPackages,
      isRailLayout,
      layoutIndex,
      layoutType,
      leadPackageType,
      pageRoute,
      pageType,
      vertical,
      isFluidWidthPage,
    } = this.props;

    return {
      layoutIndex,
      layoutType,
      vertical,
      pageRoute,
      isFirstRail,
      isRailLayout,
      leadPackageType,
      curationAutofill,
      adsDisabled,
      nonPackageAdsDisabled,
      isFirstLayoutWithPackages,
      isFluidWidthPage,
      pageType,
    };
  }

  render() {
    const { children } = this.props;
    return (
      <LayoutContextProvider.Provider value={this.getChildContext()}>
        {children}
      </LayoutContextProvider.Provider>
    );
  }
}

export default LayoutContext;
