import React from 'react';
import i18next from 'i18next';

export const concatMap = (array, fn) => array.reduce(
  (accum, ...args) => accum.concat(fn(...args)),
  [],
);

// Fisher-Yates shuffle function taken and slightly modified from:
// https://bost.ocks.org/mike/shuffle/
export const shuffle = (_array) => {
  // dupe the array
  const array = _array.slice();

  let currentIndex = array.length;
  let tempItem;
  let indexToSwap;

  // While there remain elements to shuffle…
  while (currentIndex) {
    // Pick a remaining element…
    indexToSwap = Math.floor(Math.random() * currentIndex);

    currentIndex -= 1;

    // And swap it with the current element.
    tempItem = array[currentIndex];
    array[currentIndex] = array[indexToSwap];
    array[indexToSwap] = tempItem;
  }

  return array;
};

function defaultSort(a, b) {
  if (a.value > b.value) return 1;
  if (a.value < b.value) return -1;
  // else, values are the same, sort by index
  if (a.index > b.index) return 1;
  if (a.index < b.index) return -1;
  return 0;
}

// stable sort with a map to extract value
// see here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#Sorting_with_map
export function stableSort(array, valueGetter = (x) => x, sortFn = defaultSort) {
  // add an `index`, use the valueGetter to get the actual value (could involve some complex logic)
  const mappedVals = array.map((item, index) => ({ index, value: valueGetter(item, index) }));

  // `sort` sorts in place
  mappedVals.sort(sortFn);

  return mappedVals.map((item) => array[item.index]);
}

// Adds separators to list of items.
// Ex: Item, item and item.
export const reduceCSL = (acc, cur, idx, src) => {
  if (idx > 0) {
    acc.push(idx + 1 < src.length ? ', ' : ` ${i18next.t('and')} `);
  }
  acc.push(cur);
  return acc;
};

// Adds commas between any list of items and
export const punctuateList = (acc, cur, idx, src) => {
  let accWithPunctuation = acc;
  const isFirstItem = idx === 0;
  const isLastItem = idx + 1 === src.length;

  if (isFirstItem) return accWithPunctuation.concat(cur);

  accWithPunctuation = isLastItem
    ? acc.concat(<React.Fragment key="and-conjunction">{` ${i18next.t('and')} `}</React.Fragment>)
    : acc.concat(<React.Fragment key={`comma-${idx}`}>{', '}</React.Fragment>);

  return accWithPunctuation.concat(cur);
};
