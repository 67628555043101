import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const LeadBorderTop = ({ types, customStyles }) => {
  const typeStyles = types.map((type) => `${type}-border` || '');
  return <div className={classNames(typeStyles, customStyles)} />;
};

LeadBorderTop.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  customStyles: PropTypes.string,
};

LeadBorderTop.defaultProps = {
  types: ['collectionLead', 'showLead'],
  customStyles: '',
};

export default LeadBorderTop;
