import React from 'react';
import PropTypes from 'prop-types';

import { Picture } from 'components/Picture';
import RawImage from 'components/RawImage';

import { isResizableImage } from 'lib/imageUtils';
import { getAimsSourceImage } from 'lib/isAimsUrl';

const Thumbnail = ({
  thumbnail,
  additionalStyles,
  label,
  responsiveFlavors,
  originalWidth,
  originalHeight,
}) => {
  if (!isResizableImage(thumbnail)) {
    return (
      <RawImage
        src={thumbnail}
        alt={label}
        minWidth={100}
        minHeight={100}
        originalWidth={originalWidth}
        originalHeight={originalHeight}
        layout="fill"
        className={additionalStyles.raw}
      />
    );
  }

  return (
    <Picture
      lazyloaded
      responsiveFlavors={responsiveFlavors}
      url={getAimsSourceImage(thumbnail)}
      retina
      className={additionalStyles.thumbnail}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    />
  );
};

Thumbnail.propTypes = {
  additionalStyles: PropTypes.shape({
    raw: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
  label: PropTypes.string,
  originalHeight: PropTypes.number,
  originalWidth: PropTypes.number,
  responsiveFlavors: PropTypes.shape({}),
  thumbnail: PropTypes.string.isRequired,
};

Thumbnail.defaultProps = {
  additionalStyles: {
    raw: null,
    thumbnail: null,
  },
  originalHeight: null,
  originalWidth: null,
  label: '',
  responsiveFlavors: {},
};

export default Thumbnail;
