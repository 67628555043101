function slideshowLink({
  primaryVertical, primarySection, urls, url: urlObj,
}) {
  const url = `/${primaryVertical.slug}/${primarySection.slug}/${(urlObj || urls).slug}`;

  return url.replace(/n([0-9]+)$/, 'ncsl$1');
}

const toURL = (slug) => slug.replace(/\s/g, '-').toLowerCase();

const isVerticalUrl = (primarySource) => /mach|better|think|globalcitizen/i.test(primarySource);

export default function BuildUrl(tease) {
  const {
    type,
    subType,
    videoType,
    computedValues,
  } = tease;

  if (computedValues) {
    return computedValues.url;
  }

  /*
    fronts need the old url format. this will be redone
    once fronts are supported by bento api     // urls: { slug, canonical },
  */
  let slug;
  let canonical;

  if (tease.urls) {
    ({ slug } = tease.urls);
    ({ canonical } = tease.urls);
  } else if (tease.url) {
    ({ slug } = tease.url);
    canonical = tease.url.canonical || tease.url.primary;
  } else {
    return '';
  }

  if (type === 'video') {
    const { source: { name: primarySource } } = tease;

    if (videoType === 'Non Broadcast Live Video') {
      return canonical;
    }

    if (isVerticalUrl(primarySource)) {
      return toURL(`/${primarySource}/video/${slug}`);
    }

    return canonical;
  }

  const {
    primaryVertical,
    primarySection,
  } = tease;

  if (type === 'slideshow') {
    if (primaryVertical && isVerticalUrl(primaryVertical.slug)) {
      return slideshowLink(tease);
    }

    return canonical;
  }

  if (type === 'article') {
    if (subType === 'externalLink') {
      return canonical;
    }

    if (!primaryVertical) {
      return canonical.replace('https://www.nbcnews.com', '');
    }

    if (primaryVertical && isVerticalUrl(primaryVertical.slug)) {
      return `/${primaryVertical.slug}/${primarySection.slug}/${slug}`;
    }
  }

  return canonical;
}
