import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * A small, simple title type, for use with packages
 * @param {string} [title] section title to display
 * @returns {React.ReactElement}
 */
const PackageTitleSmall = ({ title, classes }) => {
  if (!title) return null;

  return (
    <h3 className={`${styles.title} ${classes}`} data-test="title-small">
      {title}
    </h3>
  );
};

PackageTitleSmall.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

PackageTitleSmall.defaultProps = {
  title: '',
  classes: '',
};

export { PackageTitleSmall };
