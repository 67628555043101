import React from 'react';
import PropTypes from 'prop-types';

function RawImage({
  src,
  alt = null,
  className = null,
  loadingLazy = false,
  originalWidth = null,
  originalHeight = null,
}) {
  return (
    <img
      width={originalWidth}
      height={originalHeight}
      loading={loadingLazy ? 'lazy' : 'auto'}
      src={src}
      alt={alt}
      className={className}
      data-testid="raw-image"
    />
  );
}

RawImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  loadingLazy: PropTypes.bool,
  originalWidth: PropTypes.number,
  originalHeight: PropTypes.number,
};

RawImage.defaultProps = {
  alt: null,
  className: null,
  loadingLazy: false,
  originalWidth: null,
  originalHeight: null,
};

export default RawImage;
