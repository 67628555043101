import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import { sanitize } from 'lib/social';

import Link from 'components/Link';

/* Important: Making changes to this file could have an impact on shorthand articles */

const block = 'byline-social';
export const BylineSocial = ({ person, additionalClasses }) => {
  const email = get(person, 'email', null);
  const social = get(person, 'socialMediaProfiles', null);
  if (!email && !social) {
    return null;
  }

  return (
    <span
      className={classNames(block, additionalClasses)}
      data-test={block}
    >
      {social?.twitter && (
        <Link
          href={`https://twitter.com/${sanitize(social.twitter)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => { event.stopPropagation(); }}
          className={`${block}--link`}
          data-test={`${block}--link`}
        >
          <span className="icon icon-twitter" />
        </Link>
      )}
      {social?.facebook && (
        <Link
          href={`https://facebook.com/${sanitize(social.facebook)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => { event.stopPropagation(); }}
          className={`${block}--link`}
          data-test={`${block}--link`}
        >
          <span className="icon icon-facebook" />
        </Link>
      )}
      {email && (
        <Link
          href={`mailto:${email.trim()}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => { event.stopPropagation(); }}
          className={`${block}--link`}
          data-test={`${block}--link`}
        >
          <span className="icon icon-email" />
        </Link>
      )}
    </span>
  );
};

BylineSocial.propTypes = {
  person: PersonPropType,
  additionalClasses: PropTypes.string,
};

BylineSocial.defaultProps = {
  person: {},
  additionalClasses: '',
};
