export const paidContentCard = 'paidContentCard';
export const paidContentList = 'paidContentList';

export const teaseCard = {
  type: paidContentCard,
};

export const teaseList = {
  type: paidContentList,
};

export const aLaCarte = {
  msnbc: {
    isInRail: true,
    isRailLayout: true,
    pageRoutes: ['/'],
    position: 3,
    type: paidContentList,
  },
};

export const isPaidContentType = (type) => [paidContentCard, paidContentList].includes(type);

/**
 * Basic logic to insert a paidContent card into a list of items
 * @param {array} items
 * @param {object} config
 * @param {object} context
 */
export const insertPaidContent = (items, config, context) => {
  const {
    adsDisabled = true,
    isInRail,
    isRailLayout,
    pageRoute,
    vertical,
  } = context;

  // Ads are not disabled and configuration for vertical exists
  if (adsDisabled || typeof config === 'undefined' || typeof config[vertical] === 'undefined') {
    return items;
  }

  const {
    [vertical]: placement,
  } = config;

  if (items.length >= (placement.position + 1)
    && isRailLayout === placement.isRailLayout
    && isInRail === placement.isInRail
    && placement.pageRoutes.includes(pageRoute)
  ) {
    // Make copy of original items array
    const newItems = [];
    for (let i = 0; i < items.length; i += 1) {
      // Add item at position to new items array
      newItems.push(items[i]);
      // Insert paidContent tease at configured position
      if (i + 1 === placement.position) {
        newItems.push({ type: placement.type });
      }
    }
    return newItems;
  }

  return items;
};

/**
 * @param {object} context
 */
export const getTaboolaConfig = ({
  isMobileOnlyComponent,
  type,
  vertical,
}) => {
  if (vertical === 'today') {
    if (type === paidContentList) {
      return {
        mode: 'thumbnails-b',
        container: 'taboola-homepage-right-rail---bento',
        placement: 'Homepage Right Rail - Bento',
      };
    }
    if (isMobileOnlyComponent) {
      return {
        mode: 'thumbnails-a',
        container: 'taboola-homepage-mobile-thumbnail---bento',
        placement: 'Homepage Mobile Thumbnail - Bento',
      };
    }
    return {
      mode: 'thumbnails-a',
      container: 'taboola-homepage-thumbnail---bento',
      placement: 'Homepage Thumbnail - Bento',
    };
  }

  if (type === paidContentList) {
    return {
      mode: 'thumbnails-r',
      container: 'taboola-homepage-text-link---bento',
      placement: 'Homepage Text Link - Bento',
    };
  }

  return {
    mode: 'thumbnails-s',
    container: 'taboola-homepage-thumbnail---bento',
    placement: 'Homepage Thumbnail - Bento',
  };
};
