import React, { useContext } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { VerticalContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { RENDER_SHORT_BIO } from 'lib/brandFeatures';

import {
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import { generateElementKey } from 'lib/stringUtils';

/* Important: Making changes to this file could have an impact on shorthand articles */

const generateLongBioView = (bios, block, additionalClasses) => (
  bios.map((bio, index) => {
    if (bio?.type === 'markup') {
      const key = generateElementKey(index);
      const {
        html,
        element: Element,
      } = bio;

      return (
        <Element
          key={key}
          dangerouslySetInnerHTML={{ __html: html }}
          className={classNames(block, additionalClasses)}
          data-test={block}
        />
      );
    }
    // TODO: are there other types?
    return null;
  })
);

export const BylineBio = ({ person, additionalClasses }) => {
  const vertical = useContext(VerticalContext);
  const shouldDisplayShortBio = getFeatureConfigForBrand(RENDER_SHORT_BIO, vertical);
  const block = 'byline-bio';
  const shortBio = get(person, 'shortBio', null);
  const longBio = get(person, 'bio', null);

  if (!shortBio && !longBio) {
    return null;
  }

  return (
    <div className="expanded-byline__bio-wrapper">
      {shouldDisplayShortBio && shortBio ? (
        <p
          className={classNames(block, additionalClasses)}
          data-test={block}
        >
          {shortBio}
        </p>
      ) : (
        generateLongBioView(longBio, block, additionalClasses)
      )}
    </div>
  );
};

BylineBio.propTypes = {
  person: PersonPropType,
  additionalClasses: PropTypes.string,
};

BylineBio.defaultProps = {
  person: null,
  additionalClasses: '',
};
