import React from 'react';
import Head from 'next/head';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import {
  person as PersonPropType,
} from 'lib/CustomPropTypes';

import { LinkIfHref } from 'components/Link';
import Thumbnail from 'components/Thumbnail';
import { CoverSpreadPeacockAvatar } from 'components/svgs/CoverSpreadPeacockAvatar';
import './styles.themed.scss';

/* Important: Making changes to this file could have an impact on shorthand articles */

const block = 'byline-thumbnail';
export const BylineThumbnail = ({
  person,
  additionalClasses,
  additionalClassesNoImage,
  position,
  chatStyle,
  isLinked,
}) => {
  if (!person) {
    return null;
  }

  const thumbnail = get(person, ['primaryImage', 'url', 'primary'], '');
  const label = get(person, ['primaryImage', 'altText'], null);
  const defaultLabel = 'Author Thumbnail';
  const to = get(person, ['url', 'primary'], '');

  if (!thumbnail) {
    return chatStyle ? (
      <span
        className={classNames(block, `${block}--generic-thumbnail`, additionalClasses)}
        data-test={block}
      >
        {(isLinked && to) ? (
          <LinkIfHref href={to}>
            <CoverSpreadPeacockAvatar alt={defaultLabel} />
          </LinkIfHref>
        ) : (
          <CoverSpreadPeacockAvatar alt={defaultLabel} />
        )}
      </span>
    ) : (
      <span
        className={classNames(block, `${block}--no-image`, additionalClassesNoImage)}
        data-test={block}
        data-testid={block}
      />
    );
  }

  return (
    <>
      <Head>
        <meta name={`branch:deeplink:authorThumbnail${position}`} content={thumbnail} />
      </Head>

      <div
        className={classNames(block, `${block}--has-image`, additionalClasses)}
        data-test={block}
        data-testid={block}
      >
        <LinkIfHref href={isLinked && to}>
          <Thumbnail
            label={label}
            originalHeight={48}
            originalWidth={48}
            responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_60x60 }}
            thumbnail={thumbnail}
          />
        </LinkIfHref>
      </div>
    </>
  );
};

BylineThumbnail.propTypes = {
  additionalClasses: PropTypes.string,
  additionalClassesNoImage: PropTypes.string,
  person: PersonPropType,
  position: PropTypes.number,
  chatStyle: PropTypes.bool,
  isLinked: PropTypes.bool,
};

BylineThumbnail.defaultProps = {
  person: null,
  additionalClasses: '',
  additionalClassesNoImage: '',
  chatStyle: false,
  isLinked: true,
  position: null,
};
